/** @jsx jsx */
import { jsx, Box, Heading, Spinner } from 'theme-ui';
import { HeaderSpacing } from "../components/page"
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../components/seo';
import { PageRoot } from '../data/content';
import SplitPage from "../components/split-page";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dot } from "../components/content-page";
import SupportForm from '../components/joinform/support';
import { useTextDirection } from '../i18n';
import Helmet from "react-helmet";
import qs from 'query-string'
import { useLocation } from '@reach/router';
import { analytics } from '../data/analytics';
import { isNullOrWhitespace } from '../components/joinform/join-data';
import { CustomState, FundraiserAction } from '../components/actions';

const FundraiserPage: PageRoot<{}> = ({ location, location: { pathname } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const state = location.state as CustomState

  return (
    <Fragment>
      <HeaderSpacing />
      <Box sx={{
        px: 4,
        py: [4, 4, 6],
        top: 0,
        maxWidth: "700px",
        margin: "0px auto",
        color: "#280000"
      }}>
        <FundraiserAction state={state} language={language} />
      </Box>
    </Fragment >
  )
}

export default FundraiserPage